import React from "react";
import "./Skills.css";
function Skills() {
  const skills = [
    {
      id: 2,
      title: "Java",
      xp: "18 months",
      level: "Intermediate",
      image: "java.png",
    },
    {
      id: 12,
      title: "HTML",
      xp: "2 years",
      level: "Advanced",
      image: "html.png",
    },
    {
      id: 1,
      title: "JavaScript",
      xp: "2 years",
      level: "Advanced",
      image: "js.png",
    },
    {
      id: 4,
      title: "React.js",
      xp: "6 months",
      level: "Beginner",
      image: "react.png",
    },

    {
      id: 6,
      title: "C",
      xp: "3 months",
      level: "Beginner",
      image: "c.png",
    },

    {
      id: 9,
      title: "PHP",
      xp: "3 months",
      level: "Novice",
      image: "php.png",
    },

    {
      id: 10,
      title: "React Native",
      xp: "6 month",
      level: "Beginner",
      image: "react-native.png",
    },
    {
      id: 11,
      title: "Node.js",
      xp: "3 months",
      level: "Novice",
      image: "node.png",
    },

    {
      id: 5,
      title: "MySQL",
      xp: "1 year",
      level: "Beginner",
      image: "mysql.png",
    },
    {
      id: 3,
      title: "Kali",
      xp: "3 years",
      level: "Moderate",
      image: "kali.png",
    },
    {
      id: 7,
      title: "Git",
      xp: "1 year",
      level: "Beginner",
      image: "git.png",
    },
    {
      id: 8,
      title: "Bash",
      xp: "2 years",
      level: "Beginner",
      image: "linux.png",
    },
  ];

  return (
    <div className="skills-container">
      <div className="skill-thumbnails">
        {skills.map((skill) => (
          <div key={skill.id} className="skill-thumbnail">
            <div className="thumbnail-image-s">
              <img src={skill.image} alt={skill.title} />
            </div>
            <div className="skill-details">
              <h2>{skill.title}</h2>
              <p className="xp">{skill.xp} </p>
              <p className="level">{skill.level} </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skills;
